
<template>
  <config-code apiname="webcategory" idname="webCategoryId" labelname="รหัสหมวดหมู่หน้าเว็บ"></config-code>
</template>

<script>
import ConfigCode from "./ConfigCode.vue";
export default {
  components: {
    ConfigCode
  }
};
</script>

